<template>
  <div>
    <section v-if="$auth.db.hashid">

      <b-container>
        <h1>Profielgegevens</h1>
        <b-card class="mt-5 mt-md-0">
          <div v-if="$auth.db.family.image" class="background-change">
            <img :src="$auth.db.family.image_base64" />
          </div>
          <div class="wrapper" v-bind:style="{ 'top': $auth.db.family.image ? '7em' : '-5em' }">
            <img :src="$auth.db.image ? $auth.db.image_base64 : require('@/assets/images/avatars/generic-avatar.png')" class="profile-image rounded-circle" alt="">
            <div class="overlay" @click="onPickFile">
              <p class="h2">
                <b-icon class="icon" icon="upload" variant="light"></b-icon>
              </p>
              <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFilePicked" />
            </div>
          </div>
          <b-row class="justify-content-center" v-bind:class="{ 'mt-5': $auth.db.family.image }">
            <b-col cols="12" md="8" v-bind:class="{ 'mt-5': $auth.db.family.image }">
              <validation-observer ref="accountHolderData">
                <b-form @submit.prevent>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Voornaam" label-cols-md="4">
                        <validation-provider #default="{ errors }" name="voornaam" rules="required">
                          <b-form-input placeholder="Jan" type="text" v-model="$auth.db.firstname" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Tussenvoegsel en achternaam" label-cols-md="4">
                        <b-row>
                          <b-col cols="4">
                            <validation-provider #default="{ errors }" name="tussenvoegsel" rules="">
                              <b-form-input placeholder="de" type="text" v-model="$auth.db.infix" :state="errors.length > 0 ? false : null" />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col cols="8">
                            <validation-provider #default="{ errors }" name="achternaam" rules="required">
                              <b-form-input type="text" placeholder="Vries" v-model="$auth.db.lastname" :state="errors.length > 0 ? false : null" />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Email" label-cols-md="4">
                        <validation-provider #default="{ errors }" name="email" rules="email|required">
                          <b-form-input type="email" :disabled="true" v-model="$auth.db.email" placeholder="jan.devries@gmail.com" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Telefoonnummer" label-cols-md="4">
                        <validation-provider #default="{ errors }" name="telefoonnummer" :rules="{ required: true, regex: /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/, }">
                          <cleave class="form-control" v-model="$auth.db.phone" :options="maskOptions.phone" :state="errors.length > 0 ? false : null" />
                          <small v-if="$auth.db.phone" class="text-danger">{{ $auth.db.phone.length > 3 ? errors[0] : null }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Geboortedatum" label-cols-md="4">
                        <validation-provider #default="{ errors }" name="geboortedatum" rules="required">
                          <cleave v-model="$auth.db.birthdate" :raw="false" placeholder="01-10-1987" :options="maskOptions.date" class="form-control" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" v-if="$auth.user && $auth.hasRole(['admin', 'sender'])">
                      <b-form-group label="Familiefoto" label-cols-md="4">
                        <validation-provider #default="{ errors }" name="Familiefoto" rules="required">
                          <b-form-file placeholder="Upload een familiefoto" drop-placeholder="Sleep de foto hiernaar toe" accept="image/*" browse-text="Zoek" @change="onFamilyImageChange">

                          </b-form-file>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <b-form-group label="Two factor authentication" label-cols-md="4">
                        <b-form-checkbox checked="true" v-model="$auth.user['https://familiebank.nl/mfa']" name="check-button" @change="changeMFA()" switch inline>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>

          <b-row class="justify-content-center mt-3">
            <b-col cols="12" md="8">
              <validation-observer>
                <b-form>
                  <b-row>
                    <b-col cols="12" sm="6" md="4">
                      <b-form-group label="Straat">
                        <validation-provider #default="{ errors }" name="straat" rules="required">
                          <b-form-input placeholder="Groningerstraat" v-model="$auth.db.street" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="6" md="4">
                      <b-form-group label="Huisnummer">
                        <validation-provider #default="{ errors }" name="huisnummer" rules="required|alpha_num">
                          <b-form-input v-model="$auth.db.housenumber" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="6" md="4">
                      <b-form-group label="Postcode">
                        <validation-provider #default="{ errors }" name="postcode" rules="required|length:6">
                          <b-form-input placeholder="1111AB" v-model="$auth.db.postcode" v-mask="'####AA'" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="6" md="4">
                      <b-form-group label="Stad">
                        <validation-provider #default="{ errors }" name="stad" rules="alpha|required">
                          <b-form-input placeholder="Plaats" v-model="$auth.db.city" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col cols="12" sm="6" md="4" >
                      <b-form-group
                        label="Provincie"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="provincie"
                          rules="required"
                        >
                          <b-form-select 
                            v-model="$auth.db.county" 
                            :options="['Groningen', 'Friesland', 'Drenthe', 'Overijssel', 'Flevoland', 'Gelderland', 'Utrecht', 'Noord-Holland', 'Zuid-Holland', 'Zeeland', 'Noord-Brabant', 'Limburg']" 
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col> -->

                    <b-col cols="12" sm="6" md="4">
                      <b-form-group label="Land">
                        <validation-provider #default="{ errors }" name="land" rules="required">
                          <b-form-input v-model="$auth.db.country" disabled :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </section>

    <section>
      <b-container class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <b-row class="">
          <b-col cols="12" sm="" class="d-flex flex-column flex-sm-row">
            <b-button class="mb-1 mb-sm-0 mr-1" variant="success" @click="updateUser()">Opslaan</b-button>
            <b-button class="mb-1 mb-sm-0 mr-1" v-b-modal.change-password variant="warning">Wachtwoord wijzigen</b-button>
            <b-modal id="change-password" no-enforce-focus no-close-on-backdrop modal-class="modal-danger" ok-variant="warning" ok-title="Wijzigen" cancel-title="Annuleer" title="Wijzigen van wachtwoord" @ok="changePassword()">
              <b-card-text>
                Je staat op het punt een aanvraag te doen om je wachtwoord te wijzigen. Dit kan niet ongedaan worden gemaakt.
              </b-card-text>
            </b-modal>
            <b-button v-if="$auth.user && $auth.hasRole(['admin', 'sender'])" v-b-modal.delete-account variant="danger" v-b-tooltip.hover title="Wanneer je je account stopzet, worden betalingen gepauzeerd. Je data blijft nog wel behouden. Als je dat wilt verwijderen, stuur een e-mail naar info@familiebank.nl">Account stopzetten</b-button>
            <b-modal id="delete-account" no-enforce-focus no-close-on-backdrop modal-class="modal-danger" ok-variant="danger" ok-title="Verwijderen" cancel-title="Annuleer" title="Verwijder account" @ok="deleteAccount()">
              <b-card-text>
                Je staat op het punt je account te verwijderen. Dit kan niet ongedaan worden gemaakt.
              </b-card-text>
            </b-modal>
          </b-col>
        </b-row>
      </b-container>
    </section>

  </div>
</template>

<script>
// import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import datepickerLabel from '@/components/datepicker.js'
import { required, email, min } from '@validations'
import { regex, alpha_num } from "vee-validate/dist/rules"
import Cleave from 'vue-cleave-component'


import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';


Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  data() {
    let max_birth_date = (new Date()).add(-18).years().toISOString().split('T')[0];

    return {
      maskOptions: {
        date: {
          date: true,
          datePattern: ['d', 'm', 'Y'],
          delimiter: "-",
          dateMin: '1920-01-01',
          dateMax: max_birth_date
        },
        phone: {
          phone: true,
          phoneRegionCode: "NL",
          prefix: "+31",
        }
      },
      datepickerLabel,
      country: "Nederland",
      updated_image: false,
      // validation rules
      required,
      email,
      regex,
      min,
      alpha_num,
      user_image: null
    }
  },

  mounted() {

  },
  computed: {

  },
  methods: {
    deleteAccount() {
      let _this = this;
      this.$http.post("/user/self/block", {
        _method: "PATCH",
      }).then(() => {
        _this.$swal(
          'Account stopgezet!',
          'Je account is stopgezet',
          'success'
        );
        _this.$auth.logout();
      });
    },
    changePassword() {
      let _this = this;
      this.$http.post("/user/password", {
        _method: "PATCH",
      }).then(() => {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: "Wij hebben je zojuist een mail verstuurd.",
            icon: 'EditIcon',
            variant: 'success',
          },
        });
      });
    },
    updateUser() {
      let _this = this;

      //quick hacks
      let updatedData = JSON.parse(JSON.stringify(this.$auth.db));
      updatedData.birthdate = this.$auth.db.birthdate.split("-").reverse().join("-");

      this.$http.post("/user/self", { ...updatedData, _method: "PATCH" }).then(response => {
        response.data.user.birthdate = response.data.user.birthdate.split("-").reverse().join("-");
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Profiel bijgewerkt!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
    },
    onPickFile() {
      this.$refs.fileInput.click()
    },
    onFilePicked(event) {
      let _this = this;
      const files = event.target.files
      if (!files[0]) {
        return;
      }
      if (files[0].size > 4 * 1024 * 1000) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Foto is te groot, max 4MB',
            icon: 'InfoIcon',
            variant: 'danger',
          },
        });
      } else {

        let fd = new FormData();
        fd.append("hashid", this.$auth.db.hashid);
        fd.append("image", files[0]);
        fd.append("_method", "PATCH");

        console.log(fd);
        this.$http.post("/user/image", fd).then((response) => {
          if (response) {
            _this.$auth.db.image = response.data.image;
            _this.$auth.db.image_base64 = response.data.image_base64;
            _this.updated_image = true;
            _this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profiel bijgewerkt!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          }
        });
      }
    },
    changeMFA() {
      let _this = this;
      this.$http.post("/user/mfa", {
        mfa: this.$auth.user['https://familiebank.nl/mfa'],
        _method: "PATCH",
      }).then(() => {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Twee-factor authenticatie bijgewerkt!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      });
    },
    onFamilyImageChange(event) {
      let _this = this;
      const files = event.target.files
      if (!files[0]) {
        return;
      }
      if (files[0].size > 4 * 1024 * 1000) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Foto is te groot, max 4MB',
            icon: 'InfoIcon',
            variant: 'danger',
          },
        });
      } else {
        let fd = new FormData();
        fd.append("hashid", this.$auth.db.family.hashid);
        fd.append("image", files[0]);
        fd.append("_method", "PATCH");

        this.$http.post("/family/image", fd).then((response) => {
          if (response) {
            _this.$auth.db.family.image = response.data.image;
            _this.$auth.db.family.image_base64 = response.data.image_base64;
            _this.updated_image = true;
            _this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Familiefoto bijgewerkt!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          }
        });
      }
    },
  }
}
</script>

<style lang="scss">
.wrapper:hover .overlay {
  opacity: 1;
}

.wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -5em;
  height: 100px;
  width: 100px;

  .profile-image {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 100%;
    border: 5px solid white;
    -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  }

  .overlay {
    position: absolute;
    border-radius: 100%;
    border: 5px solid white;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(102, 102, 102, 0.75);

    .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.background-change {
  position: absolute;

  height: 200px;

  width: 100%;
  overflow: hidden;
  border-radius: 1em 1em 0 0;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profile-label {
  font-size: 1.5em;
}
</style>
